import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const video = this.element.querySelector('video');
        const audio = this.element.querySelector('audio');
        const av = video || audio;

        const disableScrubbing =
            this.element.dataset?.disableScrubbing === 'true';

        let features = [
            'playpause',
            'current',
            'duration',
            'volume',
            'audioposter',
            'podcastrequest',
            //'ads',
            //'adsaudioposter',
            //'aiirads',
        ];

        if (!disableScrubbing) {
            features = [...features, 'skipback', 'jumpforward', 'progress'];
        }

        // This plugin is loaded globally via a script tag added in Front-end if aiir-media-player class is present
        new window.MediaElementPlayer(av, {
            stretching: 'responsive',
            jumpForwardInterval: 20,
            skipBackInterval: 20,
            alwaysShowControls: true,
            features,

            // aiirAdTagUrl: '../plugins/aiir-ads/aiir-ads-example-audio.json',
            // aiirAdTagUrl: 'http://ads.aiir.net/?t=pageads&s=20&pos[preroll][]=rp&pos[preroll][]=pv&record_imps=0',

            // TODO what should this path be?
            pluginPath: '../build/',
            success: function (media) {},
        });
    }
}
