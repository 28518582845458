import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'commentsContainer',
        'reportContainer',
        'addCommentContainer',
        'commentForm',
        'commentInput',
        'count',
    ];

    async connect() {
        /**
         * Initialize
         */
        const initialUrl = this.element.dataset.initialUrl;

        const res = await fetch(initialUrl);
        this.commentsContainerTarget.innerHTML = await res.text();

        if (this.hasCommentInputTarget) {
            this.updateCount();
        }
    }

    async openPage(e) {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href');

        const res = await fetch(href);
        this.commentsContainerTarget.innerHTML = await res.text();
    }

    async report(e) {
        /**
         * Click link to report a comment
         */

        e.preventDefault();
        const href = e.currentTarget.getAttribute('href');

        const res = await fetch(href);
        this.reportContainerTarget.innerHTML = await res.text();
    }

    cancelReport(e) {
        e.preventDefault();
        this.reportContainerTarget.innerHTML = '';
    }

    // Called via this attribute on the form:
    //  data-action="form:submit->comments#commentSubmitted"
    commentSubmitted({detail}) {
        const {response} = detail;
        if (response.success) {
            this.addCommentContainerTarget.innerHTML = response.message;
        } else {
            alert(response.message);
        }
    }

    // Called via this attribute on the form:
    //  data-action="form:submit->comments#reportSubmitted"
    reportSubmitted({detail}) {
        const {response} = detail;
        if (response.success) {
            this.reportContainerTarget.innerHTML = response.message;
        } else {
            alert(response.message);
        }
    }

    /**
     * Character count
     */
    updateCount() {
        const maxCount = 450;
        const count = maxCount - this.commentInputTarget.value.length;

        if (
            !this.countTarget.classList.contains('safe') &&
            !this.countTarget.classList.contains('over')
        ) {
            if (count < 0) {
                this.countTarget.classList.add('over');
            } else {
                this.countTarget.classList.add('safe');
            }
        } else if (count < 0 && this.countTarget.classList.contains('safe')) {
            this.countTarget.classList.remove('safe');
            this.countTarget.classList.add('over');
        } else if (count >= 0 && this.countTarget.classList.contains('over')) {
            this.countTarget.classList.remove('over');
            this.countTarget.classList.add('safe');
        }

        this.countTarget.innerText = count;
    }
}
