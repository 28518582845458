import {Controller} from '@hotwired/stimulus';

export default class ExpanderController extends Controller {
    static targets = ['input'];

    connect() {
        if (this.hasInputTarget) {
            // Set up all targets
            // Do unchecked targets first, to ensure things that should
            //  be shown for the selected element aren't overridden
            this.inputTargets
                .filter((el) => !el.checked)
                .forEach((el) => this._handleState(el));
            this.inputTargets
                .filter((el) => el.checked)
                .forEach((el) => this._handleState(el));
        } else {
            // Just set up this.element
            const el = this.element;
            this._handleState(el);
        }
    }

    toggle(e) {
        const thisEl = e.currentTarget;

        // If this controller has inputTargets...
        if (this.hasInputTarget) {
            // If other targets are unchecked, hide their aria-controls element(s)
            this.inputTargets
                .filter((el) => el !== thisEl && !el.checked)
                .forEach((el) => this._handleState(el));
        }

        // Handle state of this element
        this._handleState(thisEl);
    }

    _handleState(el) {
        if (el.hasAttribute('aria-controls')) {
            el.setAttribute('aria-expanded', el.checked ? 'true' : 'false');
            const selector = ExpanderController.ariaControlsToSelector(
                el.getAttribute('aria-controls'),
            );
            document
                .querySelectorAll(selector)
                .forEach((el2) =>
                    el2.classList.toggle('is-hidden', !el.checked),
                );
        }
    }

    static ariaControlsToSelector(ariaControls) {
        return '#' + ariaControls.replace(' ', ', #');
    }
}
