import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    open(e) {
        const {currentTarget} = e;
        const size = e.params?.size;
        // If the size attribute is empty, open the link as normal
        if (!size) return true;

        e.preventDefault();
        const [width, height] = size.split('x');
        const url = currentTarget.getAttribute('href');
        window.open(
            url,
            'aiir_popup',
            `popup=1,width=${width},height=${height}`,
        );
    }
}
