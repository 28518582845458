import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['item'];

    connect() {
        // Get OS from user-agent, toggle options in the "Subscribe" menu
        const thisOs = this.getOS();

        this.itemTargets.forEach((el) => {
            const itemOs = el.dataset?.os;
            if (itemOs) {
                el.style.display = itemOs === thisOs ? 'block' : 'none';
            }
        });
    }

    /**
     * Basic checking of OS
     * @returns string
     */
    getOS() {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            return 'android';
        }

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'ios';
        }

        return 'desktop';
    }
}
