import {Controller} from '@hotwired/stimulus';
import Adtech from '../advertising/Adtech';
import Adsense from '../advertising/Adsense';
import Dfp from '../advertising/Dfp';
import {getScript, isEmptyObject, urlString} from '../utils/funcs';

// To use staging ads environment, add to the page request: ?staging_ads=1
const AD_SERVER_URL = '//ads.aiir.net/pageads';
const STAGING_AD_SERVER_URL = '//ads.staging.aiir.net/pageads';

/**
 * Disconnect events are called in this order:
 * 1. turbo:before-cache
 * 2. posTargetDisconnected (for each target)
 * 3. disconnect
 */

document.addEventListener('turbo:before-cache', function () {
    //console.log('ads-controller: turbo:before-cache');
    // Tear down all ads before caching the page
    // This triggers the disconnect event for each target
    document
        .querySelectorAll('[data-ads-target~="pos"]')
        .forEach((el) => el.remove());
});

function useStaging() {
    if ('URLSearchParams' in window) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('staging_ads') === '1';
    }
    return false;
}

const getServerUrl = () =>
    useStaging() ? STAGING_AD_SERVER_URL : AD_SERVER_URL;

export default class AdsController extends Controller {
    static targets = ['pos'];

    previewItems = {};
    takeoverActive = false;

    // Each target's connect event (posTargetConnected) happens before the controller's connect event
    //  so this property is used to prevent requesting ads for every position separately
    //  before the connect function is called
    isConnected = false;

    connect() {
        if (document.documentElement.hasAttribute('data-turbo-preview')) {
            // Turbo Drive is displaying a preview from the cache
            // We don't want to request ads now, because we'll do it when the cached version is replaced
            return;
        }

        this.isConnected = true;

        /**
         * Check gm.properties exists
         */
        if (!window?.gm?.properties) {
            return;
        }

        window.gm.advertising = {};

        /**
         * Get preview_ads param from query string
         * Base64-decode and parse JSON to get object with [posId] => [adGuid]
         */
        if ('URLSearchParams' in window) {
            const urlParams = new URLSearchParams(window.location.search);
            const encPreviewAds = urlParams.get('preview_ads');
            if (encPreviewAds) {
                try {
                    this.previewItems = JSON.parse(atob(encPreviewAds));
                } catch (e) {
                    console.log('Failed to decode base64 or JSON parse', e);
                }
            }
        }

        const requestItems = [];

        /**
         * Pick up ad positions in the page
         */
        this.posTargets.forEach((el) => {
            const posId = el.dataset?.posId;

            // Don't request for this position is we've asked for the preview to be blank
            if (
                this.previewItems[posId] &&
                this.previewItems[posId] === 'blank'
            ) {
                return;
            }

            requestItems.push(posId);
        });

        /**
         * Pick up a takeover background
         * Only do this if there's space to the left of the takeover wrap element
         */
        const takeoverWrapEl = document.querySelector('.js-takeover-wrap');

        if (
            takeoverWrapEl &&
            !this.takeoverActive &&
            takeoverWrapEl.getBoundingClientRect().left !== 0 &&
            (!this.previewItems['to'] || this.previewItems['to'] !== 'blank')
        ) {
            requestItems.push('to');
        }

        /**
         * Pick up CSS
         */
        if (!this.previewItems['cs'] || this.previewItems['cs'] !== 'blank') {
            requestItems.push('cs');
        }

        /**
         * If there are positions or a takeover, request ads
         */
        if (requestItems.length === 0 && isEmptyObject(this.previewItems)) {
            return;
        }

        this.buildAndExecRequest(requestItems);
    }

    disconnect() {
        //console.log('ads-controller: disconnect');
        this.previewItems = {};
        this.takeoverActive = false;
        this.isConnected = false;
    }

    posTargetConnected(el) {
        if (!this.isConnected) {
            //console.log(
            //    'ads-controller: posTargetConnected - controller not yet connected',
            //);
            return;
        }
        //console.log('ads-controller: posTargetConnected');
        const posId = el.dataset?.posId;

        // Don't request for this position is we've asked for the preview to be blank
        if (this.previewItems[posId] && this.previewItems[posId] === 'blank') {
            return;
        }

        const requestItems = [posId];
        this.buildAndExecRequest(requestItems);
    }

    posTargetDisconnected(el) {
        //console.log('ads-controller: posTargetDisconnected');
        if (el?.hasGoogleAd) {
            // Handle destroying Google ads
            // If we didn't, in a Turbo Drive environment (player)
            //  an ad wouldn't be loaded when revisiting a page with a tag ID that had already been used
            //console.log('ads-controller: hasGoogleAd', el);
            Dfp.destroySlot(el.id);
            delete el.hasGoogleAd;
        }
    }

    async buildAndExecRequest(requestItems) {
        const requestProps = {
            s: window.gm.properties.site_id,
            path_no_tll: window.gm.properties.page_path_no_tll,
            pos: requestItems,
            preview: this.previewItems,
        };

        if (window.gm.properties?.service_id) {
            requestProps.service_id = window.gm.properties.service_id;
        }

        if (window.gm.properties?.location_id) {
            requestProps.loc = window.gm.properties.location_id;
        }

        const urlParams = urlString(requestProps);
        const data = await getScript(getServerUrl() + '?' + urlParams, 'jsonp');
        this.requestCallback(data, requestItems);
    }

    requestCallback(data, requestItems) {
        if (!data.ads) return;

        const requestedItems = [
            ...requestItems,
            ...Object.keys(this.previewItems),
        ];

        let adtechPlacements = {};
        let adsenseEnabled = false;
        let dfpSlots = {};

        requestedItems.forEach((requestItem) => {
            if (data.ads[requestItem]) {
                const ad = data.ads[requestItem];

                switch (ad.type) {
                    case 'to':
                        this.takeoverActive = true;
                        import(
                            /* webpackChunkName: "advertising__takeover" */ '../advertising/Takeover'
                        ).then((module) => {
                            const Takeover = module.default;
                            Takeover.init(ad);
                        });
                        break;

                    case 'at':
                        adtechPlacements[ad.placement] = {
                            server: 'adserver.' + ad.domain,
                            network: ad.network,
                        };

                        if (ad?.responsive) {
                            // Responsive ad properties
                            adtechPlacements[ad.placement].responsive =
                                ad.responsive;
                        }
                        break;

                    case 'ga':
                        adsenseEnabled = true;
                        break;

                    case 'dc':
                        ad.tagid = 'ad-pos-' + requestItem;
                        dfpSlots[requestItem] = ad;
                }

                AdsController.renderAd(requestItem, ad);
            } else if (!['to', 'cs'].includes(requestItem)) {
                const posEl = document.getElementById(`ad-pos-${requestItem}`);
                if (posEl) {
                    posEl.remove();
                }
            }
        });

        if (!isEmptyObject(adtechPlacements)) {
            Adtech.request(adtechPlacements);
        }

        if (adsenseEnabled) {
            Adsense.request();
        }

        if (!isEmptyObject(dfpSlots)) {
            Dfp.request(dfpSlots);
        }

        /**
         * Trigger event, so the response can be picked up externally and acted on
         * E.g. if a certain ad is served, add a class to something
         *
         * Pick up like this:
         * window.addEventListener('adresponse', (e) => {
         *   console.log(e.detail);
         * });
         */
        if (window.CustomEvent) {
            const event = new CustomEvent('adresponse', {detail: data.ads});
            window.dispatchEvent(event);
        }
    }

    static renderAd(posId, data) {
        const posEl = document.getElementById(`ad-pos-${posId}`);
        switch (data.type) {
            case 'im': {
                /**
                 * Image
                 */

                posEl.innerHTML = data.html;

                break;
            }
            case 'ht': {
                /**
                 * Custom HTML
                 */

                if (data.disableIframe) {
                    posEl.innerHTML = data.html;
                    break;
                }

                /**
                 * Isolate in an iframe
                 * This resolves issues with document.write, and ad provider inception
                 * It also means you don't have to use another library like Postscribe
                 */
                // create a new <iframe> element
                const iframe = document.createElement('iframe');
                iframe.setAttribute(
                    'style',
                    'border:0;width:100%;height:' +
                        data.height +
                        'px;max-width:' +
                        data.width +
                        'px;',
                );
                //iframe.setAttribute('width', data.width);
                //iframe.setAttribute('height', data.height);
                //iframe.setAttribute('frameborder', '0');
                iframe.setAttribute('scrolling', 'no');
                iframe.setAttribute('allowtransparency', 'true');

                // append the new element to the document in position
                posEl.appendChild(iframe);

                // get a handle on the <iframe>d document (in a cross-browser way)
                let doc = iframe.contentWindow || iframe.contentDocument;
                if (doc.document) {
                    doc = doc.document;
                }

                const iframeHTML = `<!doctype html>
<html style="-webkit-text-size-adjust:none;">
<head><meta charset="utf-8"></head>
<body style="margin:0; padding:0; background:transparent; -webkit-touch-callout:none; -webkit-user-select:none;">
${data.html}
</body></html>`;

                // open, write content to, and close the document
                doc.open();
                doc.write(iframeHTML);
                doc.close();

                break;
            }
            case 'cs': {
                /**
                 * CSS
                 */

                const style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = data.css;
                document.querySelector('head').appendChild(style);

                break;
            }
            case 'at': {
                /**
                 * Adtech
                 */

                // Prepare the placement
                posEl.innerHTML = `<div id="${data.placement}"></div>`;

                break;
            }
            case 'ga': {
                /**
                 * Google Adsense
                 */

                posEl.innerHTML = data.html;

                break;
            }
        }
    }
}
