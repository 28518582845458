import $ from 'jquery';

// Key is number of items per row, value is max width this number will be used for
// If parent is wider than last width, it will just remain on this number per row regardless
const MAX_WIDTHS = {
    1: 300,
    2: 500,
    3: 700,
    4: 900,
    5: 1100,
    6: 1300,
    7: 1500,
    8: 1700,
};

const IN_VIEW_THRESHOLD = 100;

export default class Sectional {
    static insts = [];

    static pageLazyLoading = false; // Stores if the page has lazyLoading sectionals. If true, we establish window.on('scroll') to call scrollPage()
    static scrollSettleTimer = null;

    static bind(context) {
        $('.gm-sec', context || document).each((i, el) => {
            const secControl = new this($(el));
            this.insts.push(secControl);
            secControl.$cont.data('sec-inst', secControl);
        });

        /**
         * Set up resize event, to rescale sectional responsively
         */
        $(window).on('resize.redraw-sec', () => this.resize());
    }

    $cont;
    $parent;
    parentWidth = 0;
    imgHeight = null;
    fixNumPerRow = null;
    gridThreshold = 600;

    // Default is 0 - list and grid
    // 1 - list only
    // 2 - grid only
    mode = 0;

    // Current states
    numPerRow = 0;
    gridMode = false;
    lazyLoad = false;

    constructor($cont) {
        this.$cont = $cont;
        this.$parent = $cont.parent();

        if ($cont.data('mode')) {
            this.mode = $cont.data('mode');
        }

        if ($cont.data('lazy-load')) {
            this.lazyLoad = $cont.data('lazy-load');

            if (this.lazyLoad && !Sectional.pageLazyLoading) {
                /**
                 * This sectional has lazy loading and we haven't yet set up the window 'onscroll' event handler
                 */

                Sectional.pageLazyLoading = true;

                $(window).on('scroll.scroll-secs', () =>
                    Sectional.scrollPage(),
                );
            }
        }

        if (this.mode == 0 || this.mode == 2) {
            if ($cont.data('img-height')) {
                this.imgHeight = $cont.data('img-height');
            }

            if ($cont.data('per-row')) {
                this.fixNumPerRow = $cont.data('per-row');
            }

            if ($cont.data('grid-threshold')) {
                this.gridThreshold = $cont.data('grid-threshold');
            }

            this.draw();

            if (this.lazyLoad) {
                this.loadImages();
            }
        }
    }

    draw() {
        /**
         * This sectional wishes to be a grid if there is adequate space
         *
         * We need to figure out:
         * - how many should fit on a row
         * - how wide each items should be (as percentage)
         * - space between each item (as percentage)
         * - size of image inside item, if there is one
         * - account for if we insist on having a specific number of items per row
         *
         */

        this.parentWidth = this.$parent.width();

        if (
            (this.parentWidth >= this.gridThreshold && this.mode == 0) ||
            this.mode == 2
        ) {
            /**
             * Apply grid layout
             */

            if (!this.gridMode) {
                this.enterGridMode();
            }

            const oldPerRowValue = this.numPerRow;

            if (this.fixNumPerRow) {
                /**
                 * Num per row has been fixed
                 */

                this.numPerRow = this.fixNumPerRow;
            } else {
                /**
                 * No 'num per row' specific - num is based on parent width
                 */

                for (let [key, val] of Object.entries(MAX_WIDTHS)) {
                    this.numPerRow = key;
                    if (this.parentWidth < val) {
                        break;
                    }
                }
            }

            if (this.numPerRow != oldPerRowValue) {
                /**
                 * Num per row has changed, OR we are entering grid mode
                 */

                if (oldPerRowValue) {
                    this.$cont
                        .removeClass('per-row-' + oldPerRowValue)
                        .children('li')
                        .removeClass('first-in-row');
                }

                this.$cont.addClass('per-row-' + this.numPerRow);
                this.$cont.children('li:first-child').addClass('first-in-row');
                this.$cont
                    .children('li:nth-child(' + this.numPerRow + 'n+1)')
                    .addClass('first-in-row');

                /**
                 * Fixed height for images
                 */
                if (this.imgHeight) {
                    this.$cont
                        .find('.gm-sec-img')
                        .css('height', this.imgHeight);
                }
            }
        } else if (this.gridMode) {
            /**
             * Grid mode not required
             * Currently in grid mode, so exit it...
             */

            this.leaveGridMode();
        }
    }

    enterGridMode() {
        this.$cont.addClass('grid');
        this.gridMode = true;
    }

    leaveGridMode() {
        this.$cont
            .removeClass('grid per-row-' + this.numPerRow)
            .children('li')
            .removeClass('first-in-row');

        this.$cont.find('.gm-sec-img').css('height', 'auto');

        this.gridMode = false;
        this.numPerRow = 0;
    }

    loadImages() {
        const vpTop = $(window).scrollTop(),
            vpBot = vpTop + $(window).height() + IN_VIEW_THRESHOLD;

        this.$cont.find('.gm-sec-img:not(.loaded-img)').each(function () {
            const $img = $(this),
                eleTopEdge = $img.offset().top,
                eleBotEdge = eleTopEdge + $img.outerHeight();

            if (
                (eleTopEdge < vpBot && eleTopEdge >= vpTop) ||
                (eleBotEdge > vpTop && eleBotEdge < vpBot)
            ) {
                // This element is visible
                $img.addClass('loaded-img'); // in-view

                // Load image
                if ($img.data('src')) {
                    $img.attr('src', $img.data('src'));
                }
            }
        });
    }

    /**
     * Called when window is resized
     */
    static resize() {
        this.insts.forEach((obj) => {
            if (obj.mode == 0 || obj.mode == 2) {
                obj.draw();
            }
        });
    }

    /**
     * This only gets called if there are sectionals on the page with data-lazy-load="true"
     * and when the page is scrolled
     *
     * It then calls loadImages() for each sectional on the page with lazy loading enabled
     */
    static scrollPage() {
        clearTimeout(this.scrollSettleTimer);

        this.scrollSettleTimer = setTimeout(() => {
            this.insts.forEach((obj) => {
                if (obj.lazyLoad) {
                    obj.loadImages();
                }
            });
        }, 250);
    }
}
