const MOBILE_WIDTH_THRESHOLD = 800;

export function isMobileViewport() {
    return getViewport().width <= MOBILE_WIDTH_THRESHOLD;
}

/**
 * Get the viewport width and height reliably across devices
 * @returns {{width: *, height: *}}
 */
export function getViewport() {
    let e = window,
        a = 'inner';

    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }

    return {
        width: e[a + 'Width'],
        height: e[a + 'Height'],
    };
}

export function hasTouchSupport() {
    return 'ontouchstart' in document.documentElement;
}
