// Equivalent to jQuery's siblings()
// https://gomakethings.com/an-es6-way-to-get-all-sibling-elements-with-vanilla-js/
export const getSiblings = (el) =>
    Array.prototype.filter.call(
        el.parentNode.children,
        (sibling) => sibling !== el,
    );

// https://stackoverflow.com/a/61903296/2544386
// JSONP: https://gist.github.com/gf3/132080/110d1b68d7328d7bfe7e36617f7df85679a08968
export const getScript = (url, dataType = 'script') =>
    // eslint-disable-next-line no-undef
    new Promise((resolve, reject) => {
        if (dataType === 'jsonp') {
            const callbackName = `_jsonp_${Date.now()}`;
            url += url.includes('?') ? '&' : '?';
            url += 'callback=' + callbackName;

            window[callbackName] = (data) => {
                delete window[callbackName];
                resolve(data);
            };
        }

        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onerror = reject;
        script.onload = script.onreadystatechange = function () {
            const loadState = this.readyState;

            if (loadState && loadState !== 'loaded' && loadState !== 'complete')
                return;

            script.onload = script.onreadystatechange = null;

            if (dataType === 'script') {
                resolve();
            }
        };
        document.head.appendChild(script);
    });

// https://stackoverflow.com/a/73972907/2544386
export const urlString = (data) => {
    if (data == null) {
        return '';
    }

    const urlParams = new URLSearchParams();
    const rbracket = /\[\]$/;

    const add = (name, valueOrFunction) => {
        const value =
            typeof valueOrFunction === 'function'
                ? valueOrFunction()
                : valueOrFunction;
        urlParams.append(name, value == null ? '' : value);
    };

    const buildParams = (prefix, obj) => {
        if (Array.isArray(obj)) {
            obj.forEach((value, index) => {
                if (rbracket.test(prefix)) {
                    add(prefix, value);
                } else {
                    const i =
                        typeof value === 'object' && value != null ? index : '';
                    buildParams(`${prefix}[${i}]`, value);
                }
            });
        } else if (typeof obj === 'object' && obj != null) {
            for (const [name, value] of Object.entries(obj)) {
                buildParams(`${prefix}[${name}]`, value);
            }
        } else {
            add(prefix, obj);
        }
    };

    if (Array.isArray(data) || data instanceof NodeList) {
        // If an array or NodeList was passed in,
        // assume that it is a collection of form elements:
        data.forEach((el) => add(el.name, el.value));
    } else {
        for (const [name, value] of Object.entries(data)) {
            buildParams(name, value);
        }
    }

    return urlParams.toString();
};

export const isEmptyObject = (obj) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;
