import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        fullThreshold: {type: Number, default: 600},
    };

    parentEl;

    connect() {
        this.parentEl = this.element.parentElement;
        this.updateLayout();
    }

    updateLayout() {
        const parentWidth = this.parentEl.clientWidth;
        this.element.classList.toggle(
            'full-text',
            parentWidth >= this.fullThresholdValue,
        );
    }
}
