import {Controller} from '@hotwired/stimulus';

const LAYOUT_THRESHOLD = 1000;

const constructSlideUrl = (slug) =>
    window.location.origin +
    window.location.pathname +
    '?' +
    new URLSearchParams({i: slug});

export default class AlbumController extends Controller {
    static values = {
        id: String,
        images: Array,
        showingIndex: {type: Number, default: 0},
        requiredString: {
            type: String,
            default: 'This field must be completed.',
        },
    };

    static targets = [
        'photoViewer',
        'carousel',
        'carouselArrowLeft',
        'carouselArrowRight',
        'carouselInner',
        'carouselList',
        'carouselItem',
        'prev',
        'next',
        'pagination',
        'pageNumber',
        'overlayNav',
        'moreSlide',
        'photoMeta',
        'photoName',
        'photoDescr',
        'image',
        'shareBtns',
    ];

    totalImages = 0;
    totalSlides = 0;
    slides; // images + 'more albums' slide

    static documentTitle;
    static currentState = null;

    initialize() {
        AlbumController.documentTitle = document.title;
    }

    connect() {
        /**
         * Apply layout
         */
        this.updateLayout();

        /**
         * Now we know the layout and width, show the container
         */
        this.element.style.display = 'block';

        /**
         * Properties
         */
        this.slides = this.imagesValue;
        this.totalImages = this.imagesValue.length;

        /**
         * If we have 'more albums' to show,
         *  add this slide to the slides array
         */
        if (this.hasMoreSlideTarget) {
            this.slides.push({
                url: 'more-albums',
                slug: '',
            });
        }

        /**
         * Now we can record the number of slides,
         *  including the 'more albums' slide if there is one
         */
        this.totalSlides = this.slides.length;

        /**
         * Is the selected thumb visible?
         *  If not, scroll the carousel to make it visible
         */
        if (this.hasCarouselTarget) {
            this.makeCurrentThumbnailVisible(true);
        }

        /**
         * Set initial nav size if the first image has loaded
         */
        if (this.imageTarget.complete) {
            this.updateOverlayNavSize();
        }

        this.toggleNavLinksVisibility();
    }

    /**
     * Show the next image, if there is one available
     */
    next() {
        this.showingIndexValue = this.showingIndexValue + 1;
        this.updateBrowserState();
    }

    /**
     * Show the previous image, if there is one available
     */
    prev() {
        this.showingIndexValue = this.showingIndexValue - 1;
        this.updateBrowserState();
    }

    /**
     * When showingIndexValue is changed,
     *  navigate to the image specified
     */
    showingIndexValueChanged(index, prevIndex) {
        if (index < 0 || index > this.totalSlides - 1) {
            return;
        }

        this.toggleNavLinksVisibility();

        const slide = this.slides[index];

        if (slide.url === 'more-albums') {
            // Unselect item in carousel
            if (this.hasCarouselItemTarget) {
                this.carouselItemTargets.forEach((el) =>
                    el.classList.remove('on'),
                );
            }
            this.showMoreAlbumsSlide();
        } else {
            // Select item in carousel
            if (this.hasCarouselItemTarget) {
                this.carouselItemTargets.forEach((el) =>
                    el.classList.remove('on'),
                );
                const thumbEl =
                    this.carouselItemTargets[this.showingIndexValue];
                thumbEl.classList.add('on');
                this.makeCurrentThumbnailVisible();
            }

            this.showImage(prevIndex);
        }
    }

    /**
     * Show the 'more albums' slide
     */
    showMoreAlbumsSlide() {
        this.imageTarget.style.display = 'none';
        this.photoMetaTarget.style.display = 'none';
        this.paginationTarget.style.display = 'none';
        this.overlayNavTargets.forEach((el) => el.classList.add('disabled'));
        this.moreSlideTarget.style.display = 'block';
    }

    /**
     * Show the image indicated by showingIndexValue
     */
    showImage(prevIndex) {
        const showImg = this.slides[this.showingIndexValue];

        this.pageNumberTarget.innerText = this.showingIndexValue + 1;

        const prevSlide = this.slides[prevIndex] ?? null;
        if (prevSlide?.url === 'more-albums') {
            // Navigating away from 'more albums' slide
            this.moreSlideTarget.style.display = 'none';
            this.imageTarget.style.display = 'inline';
            this.paginationTarget.style.display = 'block';
        }

        this.imageTarget.setAttribute('src', showImg.url);
        this.photoNameTarget.innerText = showImg.name;
        this.photoDescrTarget.innerText = showImg.descr;
        this.photoMetaTarget.style.display = 'block';
        this.updateNavLinks();
    }

    /**
     * Make the current thumbnail visible in the carousel
     */
    makeCurrentThumbnailVisible(initial = false) {
        const thumbEl = this.carouselItemTargets[this.showingIndexValue];
        if (thumbEl) {
            const carouselWidth = this.carouselInnerTarget.offsetWidth;
            const thumbLeft = thumbEl.offsetLeft;
            const thumbWidth = thumbEl.offsetWidth;
            const newLeft = thumbLeft - (carouselWidth - thumbWidth) / 2;
            const clamped = Math.max(newLeft, 0);

            // Don't animate (scrollTo) when setting initial position
            if (initial) {
                this.carouselInnerTarget.scrollLeft = clamped;
            } else {
                this.carouselInnerTarget.scrollTo({
                    left: clamped,
                    behavior: 'smooth',
                });
            }
        }
    }

    /**
     * Click a thumbnail, navigate to that image
     */
    selectThumbnail(e) {
        const item = e.currentTarget.parentNode;
        this.showingIndexValue = this.carouselItemTargets.indexOf(item);
        this.updateBrowserState();
    }

    /**
     * Click arrow, shift carousel along
     */
    pageCarousel(e) {
        const direction = e.params.direction;
        const parentWidth = this.element.parentElement.clientWidth;
        const distanceToTravel = parseInt(parentWidth * 0.8);
        const left =
            direction === 'left' ? 0 - distanceToTravel : distanceToTravel;
        this.carouselInnerTarget.scrollBy({
            left,
            behavior: 'smooth',
        });
    }

    /**
     * Hover over thumbnail, show tip
     */
    showTip(e) {
        const a = e.currentTarget;
        const li = a.parentNode;

        const tip = document.createElement('span');
        tip.className = 'photo-tip';
        tip.innerText = a.innerText;

        this.carouselTarget.appendChild(tip);
        this.tip = tip;

        const leftPos =
            li.offsetLeft -
            this.carouselInnerTarget.scrollLeft +
            li.offsetWidth / 2 -
            tip.offsetWidth / 2;

        const clamped = Math.max(leftPos, 0);

        tip.style.left = clamped + 'px';
    }

    /**
     * Move mouse out of thumbnail, hide tip
     */
    hideTip() {
        if (this.tip) {
            this.tip.remove();
            delete this.tip;
        }
    }

    toggleNavLinksVisibility() {
        this.prevTargets.forEach((el) =>
            el.classList.toggle('disabled', this.showingIndexValue === 0),
        );
        this.nextTargets.forEach((el) =>
            el.classList.toggle(
                'disabled',
                this.showingIndexValue === this.totalSlides - 1,
            ),
        );
    }

    /**
     * Update the pagination nav links to point to the correct URLs
     */
    updateNavLinks() {
        /**
         * Update links to previous and next image
         */
        let prevURL = '#';
        let nextURL = '#';

        if (this.showingIndexValue !== 0) {
            const prevImg = this.slides[this.showingIndexValue - 1];
            prevURL = constructSlideUrl(prevImg.slug);
        }

        if (this.totalImages > this.showingIndexValue + 1) {
            const nextImg = this.slides[this.showingIndexValue + 1];
            nextURL = constructSlideUrl(nextImg.slug);
        }

        this.nextTargets.forEach((el) => el.setAttribute('href', nextURL));
        this.prevTargets.forEach((el) => el.setAttribute('href', prevURL));
    }

    /**
     * Handle window emitting 'popstate' event
     *  i.e. popstate@window->album#popState
     */
    popState(e) {
        if (e?.state?.type === 'album_image') {
            const {albumId, imageSlug} = e.state;
            if (albumId === this.idValue) {
                const ix = this.slides.findIndex((s) => s.slug === imageSlug);
                if (ix !== -1) {
                    this.showingIndexValue = ix;
                    const newURL = constructSlideUrl(imageSlug);
                    this.reloadShareBtns(newURL);
                }
            }
        } else {
            this.showingIndexValue = 1;
        }
    }

    /**
     * Update the URL using the current photo slug, so the page can be loaded directly with this photo
     * Also send a pageview to Google Analytics with this new URL
     * Also update title bar of browser
     */
    updateBrowserState() {
        /**
         * Update the URL and push state
         */
        const showingImg = this.slides[this.showingIndexValue];

        const newState = {
            type: 'album_image',
            albumId: this.idValue,
            imageSlug: showingImg.slug,
        };

        if (
            AlbumController.currentState == null ||
            (newState?.type === 'album_image' &&
                newState?.imageSlug !== AlbumController.currentState.imageSlug)
        ) {
            // Only update state if it is currently null, or it has changed

            const newTitle = `${showingImg.name} - ${AlbumController.documentTitle}`;

            const newURL = constructSlideUrl(showingImg.slug);

            if (history.pushState) {
                history.pushState(newState, newTitle, newURL);
            }

            // Store this state to check next time
            AlbumController.currentState = newState;

            /**
             * Update browser title
             */
            document.title = newTitle;

            /**
             * Send a pageview to GA
             * Relies on URL and document.title having been updated first
             */
            if (typeof ga === 'function') {
                const gaURL =
                    window.location.pathname +
                    '?' +
                    new URLSearchParams({i: showingImg.slug});
                // eslint-disable-next-line no-undef
                ga('send', 'pageview', gaURL);
            }

            /**
             * Update share buttons
             */
            this.reloadShareBtns(newURL);
        }
    }

    reloadShareBtns(newURL) {
        newURL = encodeURIComponent(newURL);

        const fb = this.shareBtnsTarget.querySelector(
            '.aiir-share__link--facebook',
        );
        if (fb) {
            fb.setAttribute(
                'href',
                `https://www.facebook.com/sharer/sharer.php?u=${newURL}`,
            );
        }

        const tw = this.shareBtnsTarget.querySelector(
            '.aiir-share__link--twitter',
        );
        if (tw) {
            tw.setAttribute(
                'href',
                `https://twitter.com/intent/tweet?url=${newURL}`,
            );
        }

        const wa = this.shareBtnsTarget.querySelector(
            '.aiir-share__link--whatsapp',
        );
        if (wa) {
            wa.setAttribute('href', `whatsapp://send?text=${newURL}`);
        }
    }

    /**
     * Called when window is resized
     *  i.e. resize@window->album#updateLayout
     */
    updateLayout() {
        const parentEl = this.element.parentElement;
        const parentWidth = parentEl.clientWidth;
        const layoutStyle = parentWidth < LAYOUT_THRESHOLD ? 'small' : 'large';

        this.element.classList.remove('small', 'large');
        this.element.classList.add(layoutStyle);

        let viewerWidth = parentWidth;

        if (layoutStyle === 'large') {
            // Update fixed width of large viewer
            viewerWidth = parentWidth - 320;
            this.photoViewerTarget.style.width = viewerWidth + 'px';
        } else {
            // On small, lose the fixed width on photo viewer
            this.photoViewerTarget.style.width = 'auto';
        }

        if (viewerWidth >= 685) {
            this.element.classList.remove('preview-2', 'preview-mobile');
            this.element.classList.add('preview-landscape', 'preview-3');
        } else if (viewerWidth >= 460) {
            this.element.classList.remove('preview-3', 'preview-mobile');
            this.element.classList.add('preview-landscape', 'preview-2');
        } else {
            this.element.classList.remove(
                'preview-landscape',
                'preview-3',
                'preview-2',
            );
            this.element.classList.add('preview-mobile');
        }

        if (this.hasCarouselTarget) {
            this.carouselTarget.style.display =
                viewerWidth < 460 ? 'none' : 'block';
            this.toggleCarouselArrows();
        }

        this.updateOverlayNavSize();
    }

    /**
     * Detect if each carousel arrow should be shown, show/hide accordingly
     *  Call on scroll event i.e. scroll->album#toggleCarouselArrows
     */
    toggleCarouselArrows() {
        const scrollLeft = this.carouselInnerTarget.scrollLeft;

        this.carouselArrowLeftTarget.classList.toggle(
            'disabled',
            scrollLeft === 0,
        );

        const scrollRight = scrollLeft + this.carouselInnerTarget.clientWidth;
        const atEnd = scrollRight === this.carouselListTarget.clientWidth;

        this.carouselArrowRightTarget.classList.toggle('disabled', atEnd);
    }

    /**
     * Update the width and height of overlay navigation controls
     */
    updateOverlayNavSize() {
        const photoWidth = this.photoViewerTarget.clientWidth;
        const overlayNavWidth = parseInt(photoWidth * 0.4);
        const photoHeight = this.imageTarget.clientHeight;
        this.overlayNavTargets.forEach((el) => {
            el.style.width = overlayNavWidth + 'px';
            el.style.height = photoHeight + 'px';
        });
    }
}
