import {getScript} from '../utils/funcs';

const ADTECH_DAC_URL = 'https://aka-cdn-ns.adtech.de/dt/common/DAC.js';

export default class Adtech {
    static async request(placements) {
        await getScript(ADTECH_DAC_URL);

        ADTECH.config.page = {
            protocol: 'https',
            params: {loc: '100', target: '_blank'},
        };

        for (let [placementID, props] of Object.entries(placements)) {
            // Placement ID has to be a number
            placementID = parseInt(placementID);

            //Config for the first AdRequest
            ADTECH.config.placements[placementID] = props;

            //Enqueueing first Ad
            ADTECH.enqueueAd(placementID);
        }

        ADTECH.executeQueue();
    }
}
