import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'suggestion', 'address', 'domain'];

    currentSuggestion = '';

    connect() {
        if (this.hasSuggestionTarget) {
            this.suggestionTarget.style.display = 'none';
        }
    }

    async check(e) {
        e.preventDefault();

        const Mailcheck = await import(
            /* webpackChunkName: "mailcheck" */ 'mailcheck'
        );

        Mailcheck.run({
            email: this.inputTarget.value,
            suggested: (suggestion) => {
                // Suggestion has been found, containing:
                // {
                //   address: 'test',          // the address; part before the @ sign
                //   domain: 'hotmail.com',    // the suggested domain
                //   topLevelDomain: 'com',    // the suggested top level domain
                //   full: 'test@hotmail.com'  // the full suggested email
                // }
                this.currentSuggestion = suggestion.full;
                this.addressTarget.innerText = suggestion.address;
                this.domainTarget.innerText = suggestion.domain;
                this.suggestionTarget.style.display = 'block';
            },
            empty: () => {
                // No suggestions
                // Clear any existing suggestions
                this.suggestionTarget.style.display = 'none';
                this.currentSuggestion = '';
            },
        });
    }

    useSuggestion() {
        this.inputTarget.value = this.currentSuggestion;
        this.suggestionTarget.style.display = 'none';
    }
}
