import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        savedPasswordInBrowserError: String,
    };

    static targets = [
        'submitMessage',
        'panel',
        'emailPanel',
        'passwordPanel',
        'emailFormActions',
    ];

    submitted({detail}) {
        const {response} = detail;

        if (this.hasSubmitMessageTarget) {
            this.submitMessageTarget.remove();
        }

        switch (response.action) {
            case 'show_msg': {
                const submitMsg = document.createElement('div');
                submitMsg.className = `gm-alert ${response.class}`;
                submitMsg.dataset.loginFormTarget = 'submitMessage';
                submitMsg.innerHTML = response.text;

                this.element.prepend(submitMsg);

                if (response.hide_panel) {
                    this.panelTargets.forEach(
                        (el) => (el.style.display = 'none'),
                    );
                }

                if (response.restore_actions) {
                    this.element.form.restoreFormActions();
                }

                if (
                    response.status &&
                    response.status === 'password_fail' &&
                    this.passwordPanelTarget.style.display === 'none'
                ) {
                    /**
                     * Password failed to match
                     * Is the email panel currently showing? If so, an incorrect password has been saved in the browser, and submitted in the hidden field
                     * Show the password field too, so it can be corrected
                     */

                    this.passwordPanelTarget.style.display = 'block';
                    this.emailFormActionsTarget.style.display = 'none';
                    this.submitMessageTarget.innerHTML =
                        this.savedPasswordInBrowserErrorValue;
                }
                break;
            }
            case 'show_panel': {
                this.panelTargets.forEach((el) => (el.style.display = 'none'));

                switch (response.panel) {
                    case 'email': {
                        this.emailPanelTarget.style.display = 'block';
                        this.emailPanelTarget
                            .querySelector('input[type="text"]')
                            .focus();
                        break;
                    }
                    case 'password': {
                        this.passwordPanelTarget.style.display = 'block';
                        this.passwordPanelTarget
                            .querySelector('input[type="password"]')
                            .focus();
                        break;
                    }
                }

                this.element.form.restoreFormActions();
                break;
            }
            case 'redirect': {
                window.location = response.url;
                break;
            }
        }
    }

    forgotPassword() {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'forgotpass';
        input.value = '1';
        this.element.appendChild(input);
    }
}
