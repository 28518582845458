import {Controller} from '@hotwired/stimulus';

/**
 * 'pattern' value is currently unused, but the thinking is an option could be 'every'
 *   meaning the HTML would be inserted after every (interval)th (tag), not just the first
 *  But our ad handling would need updating to support this
 */

export default class extends Controller {
    static values = {
        interval: {type: Number, default: 0},
        html: String,
        tag: {type: String, default: 'p'},
        pattern: {type: String, default: 'first'},
    };

    connect() {
        if (this.intervalValue === 0) return;

        // Get matching immediate children
        const childTags = this.element.querySelectorAll(
            `:scope > ${this.tagValue}`,
        );
        const numTags = childTags.length;

        // We'll either insert after the requested interval if there's enough tags
        //  or the last tag
        const prevTagNum = Math.min(this.intervalValue, numTags);
        const prevTag = childTags[prevTagNum - 1];

        if (prevTag) {
            prevTag.insertAdjacentHTML('afterend', this.htmlValue);
        }
    }
}
