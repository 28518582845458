import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['input', 'count'];

    static values = {
        limit: Number,
    };

    criticalThreshold;

    connect() {
        this.criticalThreshold = this.limitValue * 0.1; // Get 10%
        if (this.criticalThreshold > 20) this.criticalThreshold = 20; // Max 20
        if (this.criticalThreshold < 5) this.criticalThreshold = 5; // Min 5

        // Set initial state
        const charsRemaining = this.limitValue - this.inputTarget.value.length;
        this._updateInfo(charsRemaining);
    }

    update() {
        const val = this.inputTarget.value;
        const len = val.length;

        if (len >= this.limitValue) {
            this.inputTarget.value = val.substring(0, this.limitValue);
            this._updateInfo(0);
        } else {
            this._updateInfo(this.limitValue - len);
        }
    }

    _updateInfo(charactersRemaining) {
        this.countTarget.innerText = charactersRemaining;
        this.countTarget.classList.toggle(
            'is-critical',
            charactersRemaining <= this.criticalThreshold,
        );
    }
}
