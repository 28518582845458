import {getScript} from '../utils/funcs';

const GOOGLE_ADS_URL =
    '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

export default class Adsense {
    static async request() {
        await getScript(GOOGLE_ADS_URL);
        document.querySelectorAll('.adsbygoogle').forEach(() => {
            (adsbygoogle = window.adsbygoogle || []).push({});
        });
    }
}
