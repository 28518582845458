import {Controller} from '@hotwired/stimulus';
import {hasTouchSupport} from '../utils/env';

export default class extends Controller {
    show() {
        if (hasTouchSupport()) {
            this.element.classList.toggle('show');
        }
    }
}
