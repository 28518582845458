import {Controller} from '@hotwired/stimulus';

const acceptFileExts = [
    'gif',
    'jpg',
    'jpeg',
    'png',
    'eps',
    'heif',
    'heic',
    'mp3',
    'm4a',
    'mp4',
    'aac',
    'aacp',
    'wma',
    'pdf',
    'doc',
    'docx',
    'txt',
    'ppt',
    'csv',
];

export default class FormUploadController extends Controller {
    static targets = ['loading', 'dropzone', 'hiddenInputs', 'fileInput'];

    static values = {
        guidInputName: String,
        paramName: String,
        ajaxUrl: String,
        token: String,
        allowMultiple: Boolean,
        defaultMessage: {
            type: String,
            default: 'Click to select or drag and drop...',
        },
    };

    static Dropzone;
    static isInitializing = false;

    async initialize() {
        if (FormUploadController.isInitializing) return;
        FormUploadController.isInitializing = true;

        // Load Dropzone once,
        //  then emit an event to let all form-upload controllers know it is ready
        const module = await import('dropzone');
        FormUploadController.Dropzone = module.default;
        await import('dropzone/dist/dropzone.css');

        const event = new Event('dzReady');
        document.dispatchEvent(event);
    }

    connect() {
        if (FormUploadController.Dropzone) {
            this.initDropzone();
        } else {
            document.addEventListener('dzReady', () => this.initDropzone(), {
                once: true,
            });
        }
    }

    disconnect() {
        if (this.dzInst) {
            this.dzInst.destroy();
        }
    }

    initDropzone() {
        if (!FormUploadController.Dropzone) return;

        if (this.hasLoadingTarget) {
            this.loadingTarget.remove();
        }

        const config = {
            url: this.ajaxUrlValue,
            paramName: this.paramNameValue,
            addRemoveLinks: true,
            maxNumberOfFiles: 1,
            parallelUploads: 1,
            acceptedFiles: '.' + acceptFileExts.join(',.'),
            maxFilesize: 10, // in MB
            dictDefaultMessage: this.defaultMessageValue,
            params: () => ({
                token: this.tokenValue,
            }),
        };

        if (this.allowMultipleValue) {
            config.maxNumberOfFiles = 10;
        }

        // Configure the dropzone uploader
        this.dzInst = new FormUploadController.Dropzone(
            this.dropzoneTarget,
            config,
        )
            .on('success', (...args) => this._success(...args))
            .on('removedfile', (file) => this._removedFile(file));
    }

    _success(file, response) {
        // When a file, or batch of files successfully uploaded
        const input = document.createElement('input');
        input.type = 'hidden';
        input.className = 'upload-guid';
        input.name = `${this.guidInputNameValue}[]`;
        input.value = response.guid;
        input.dataset['formUploadTarget'] = 'fileInput';
        this.hiddenInputsTarget.appendChild(input);
        file.guid = response.guid;
    }

    _removedFile(file) {
        if (file?.guid) {
            const {guid} = file;
            // Delete the file from S3
            fetch(`${this.ajaxUrlValue}/${guid}`, {
                method: 'delete',
            }).then(() => {
                // File has now been deleted, remove the input from the dom
                this.fileInputTargets
                    .filter((el) => el.getAttribute('value') === guid)
                    .forEach((el) => el.remove());
            });
        }
    }
}
