function checkLoginState(loginURL, redirectURL) {
    FB.getLoginStatus(function (response) {
        if (response.status == 'connected') {
            let url =
                loginURL +
                '?' +
                'access_token=' +
                response.authResponse.accessToken +
                '&expires=' +
                response.authResponse.expiresIn +
                '&fbuid=' +
                response.authResponse.userID;

            if (typeof redirectURL !== 'undefined') {
                url += '&redirect=' + redirectURL;
            }

            window.location.href = url;
        }
    });
}

export default function exposeGlobals() {
    window.gm.fblogin = {
        checkLoginState,
    };
    window.AFE.fblogin = {
        checkLoginState,
    };

    // Generic pop-up
    // Size should be indicated in data-popup-size="600x400"
    document.querySelectorAll('[data-popup-size]').forEach((el) =>
        el.addEventListener('click', function (e) {
            const size = el.dataset?.popupSize;
            // If the size attribute is empty, open the link as normal
            if (!size) return true;
            e.preventDefault();
            const href = el.getAttribute('href');
            const [width, height] = size.split('x');
            window.open(
                href,
                'aiir_popup',
                `toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=${width},height=${height},left=50,top=50`,
            );
        }),
    );

    // Legacy Live Player
    document.querySelectorAll('.js-launch-live-player').forEach((el) =>
        el.addEventListener('click', function (e) {
            e.preventDefault();
            window.open(
                el.getAttribute('href'),
                'aiir_live_player',
                'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=800,height=560,left=50,top=50',
            );
        }),
    );

    // Radioplayer
    document
        .querySelectorAll('.js-launch-radioplayer, a.rpLaunch')
        .forEach((el) =>
            el.addEventListener('click', function (e) {
                e.preventDefault();
                let width = 380;
                let height = 700;

                const rpSizes =
                    window?.gm?.properties?.radioplayer?.window_size;
                if (rpSizes && rpSizes.length === 2) {
                    width = rpSizes[0];
                    height = rpSizes[1];
                }

                window.open(
                    el.getAttribute('href'),
                    'aiir_radioplayer',
                    `toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=${width},height=${height},left=50,top=50`,
                );
            }),
        );
}
