import {getScript} from '../utils/funcs';

const GOOGLE_DFP_JS_URL = '//www.googletagservices.com/tag/js/gpt.js';

export default class Dfp {
    static posIdToSlot = {};

    static async request(slotsObj) {
        const slots = Object.values(slotsObj);

        await getScript(GOOGLE_DFP_JS_URL);

        const googletag = window.googletag || {};
        googletag.cmd = googletag.cmd || [];

        slots.forEach((slot) => {
            const slotID = slot.dfp_slot_id;
            const slotSizes = slot.dfp_sizes;

            if (slot?.dfp_mapping) {
                // Has responsive mappings

                googletag.cmd.push(function () {
                    /*
                     * Get the largest of the viewport/sizes group, that is smaller/equal to our viewport - use those ad sizes
                     * Does not update on resize of browser!

                    var mapping = googletag.sizeMapping()
                        .addSize([1000, 800], [728, 90])
                        .addSize([320, 300], [320, 50])
                        .build();
                    */

                    var mapping = googletag.sizeMapping();

                    slot.dfp_mapping.forEach((inst) => {
                        mapping = mapping.addSize(inst.vp, inst.sizes);
                    });

                    mapping = mapping.build();

                    Dfp.posIdToSlot[slot.tagid] = googletag
                        .defineSlot(slotID, slotSizes, slot.tagid)
                        .defineSizeMapping(mapping)
                        .addService(googletag.pubads());
                });
            } else {
                // Non-responsive

                googletag.cmd.push(function () {
                    Dfp.posIdToSlot[slot.tagid] = googletag
                        .defineSlot(slotID, slotSizes, slot.tagid)
                        .addService(googletag.pubads());
                });
            }
        });

        googletag.cmd.push(function () {
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setCentering(true);
            googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();
        });

        slots.forEach((slot) => {
            googletag.cmd.push(function () {
                googletag.display(slot.tagid);
                // Store a property on the element, so we know it's a Google ad
                //  and should call destroySlot when the target is disconnected
                const posEl = document.getElementById(slot.tagid);
                posEl.hasGoogleAd = true;
            });
        });
    }

    static destroySlot(tagid) {
        if (window.googletag && Dfp.posIdToSlot[tagid]) {
            const slot = Dfp.posIdToSlot[tagid];
            delete Dfp.posIdToSlot[tagid];
            window.googletag.destroySlots([slot]);
        }
    }
}
