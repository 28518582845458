import {Controller} from '@hotwired/stimulus';

const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PASS_REGEX = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

export default class extends Controller {
    static targets = ['feedback'];

    static values = {
        type: String,
        requiredString: {
            type: String,
            default: 'This field must be completed.',
        },
        validEmailString: {
            type: String,
            default: 'This must be a valid email address.',
        },
        fieldsMatchString: {type: String, default: 'The fields do not match.'},
        validPasswordString: {
            type: String,
            default:
                'This must contain at least 8 characters, including at least 1 number, 1 lower case character and 1 uppercase character.',
        },
        passwordsMatchString: {
            type: String,
            default: 'The passwords do not match.',
        },
    };

    connect() {
        this.element.formItem = this;

        if (!this.typeValue) {
            this.typeValue = this.detectType();
        }
    }

    detectType() {
        const el = this.element;

        /**
         * Field type detection
         * Save for later use e.g. validation
         */
        if (el.classList.contains('misc-type')) {
            // Misc type
            return 'misc-type';
        } else if (el.dataset.controller.includes('form-upload')) {
            // File upload
            return 'upload';
        } else if (el.querySelector('input[type="radio"]') !== null) {
            // Single or group of radio buttons
            return 'radiobuttons';
        } else if (el.querySelector('input[type="checkbox"]') !== null) {
            // Single or group of checkboxes
            return 'checkboxes';
        } else if (el.querySelector('input[type="text"]') !== null) {
            // Text field
            return 'text';
        } else if (el.querySelector('input[type="password"]') !== null) {
            // Password field
            return 'password';
        } else if (el.querySelector('input[type="date"]') !== null) {
            // Date field
            return 'date';
        } else if (el.querySelector('textarea') !== null) {
            // Text area
            return 'textarea';
        } else if (el.querySelector('select') !== null) {
            // Select
            return 'select';
        } else if (
            el.querySelectorAll('.aiir-c-date-input__input').length === 3
        ) {
            // Date (3 inputs)
            return 'date-inputs';
        }
        return null;
    }

    validate() {
        let fieldType = this.typeValue;
        let valid = true;
        const ds = this.element.dataset;
        const required = ds?.required === 'true';
        const validateIfVisible = ds?.validateVisible === 'true';
        const requiredEle = ds?.requiredEle;
        const validFormat = ds?.validFormat;

        if (validateIfVisible) {
            /**
             * Is this element hidden? i.e. is it inside a container which is hidden?
             * If so, we don't need to validate it.
             */
            if (isElementHidden(this.element)) {
                // The control group is hidden, or there is a hidden ancestor, so don't validate the field
                fieldType = 'dont-validate';
            }
        }

        if (requiredEle && fieldType !== 'dont-validate') {
            /**
             * An element has been specified which should be checked, instead of the regular field
             */
            fieldType = 'specified';
        }

        /**
         * Validate based on the detected field type
         */
        switch (fieldType) {
            case 'text': {
                /**
                 * Text box
                 */
                const input = this.element.querySelector('input[type="text"]');
                const value = input.value.trim();

                if (required && value === '') {
                    valid = false;
                    this.showFeedback(this.requiredStringValue);
                }

                /**
                 * Check validation formats for text fields
                 */
                if (validFormat === 'email') {
                    // Email address
                    if (value !== '' && !EMAIL_REGEX.test(value)) {
                        valid = false;
                        this.showFeedback(this.validEmailStringValue);
                    }
                }

                const validMatch = ds?.validMatch;
                if (validMatch) {
                    if (document.querySelector(validMatch).value !== value) {
                        valid = false;
                        this.showFeedback(this.fieldsMatchStringValue);
                    }
                }
                break;
            }
            case 'password': {
                /**
                 * Password
                 */
                const input = this.element.querySelector(
                    'input[type="password"]',
                );
                const value = input.value.trim();

                if (required && value === '') {
                    valid = false;
                    this.showFeedback(this.requiredStringValue);
                }

                /**
                 * Check validation formats for text fields
                 */
                if (validFormat === 'password') {
                    // Password
                    if (value !== '' && !PASS_REGEX.test(value)) {
                        valid = false;
                        this.showFeedback(this.validPasswordStringValue);
                    }
                }

                const validMatch = ds?.validMatch;
                if (validMatch) {
                    if (document.querySelector(validMatch).value !== value) {
                        valid = false;
                        this.showFeedback(this.passwordsMatchStringValue);
                    }
                }
                break;
            }
            case 'textarea':
            case 'select':
            case 'date': {
                /**
                 * Text area, Select, Date
                 */
                if (required) {
                    const input = this.element.querySelector(
                        'textarea, select, input[type="date"]',
                    );
                    const value = input.value.trim();
                    if (value === '') {
                        valid = false;
                        this.showFeedback(this.requiredStringValue);
                    }
                }
                break;
            }
            case 'date-inputs':
            case 'time-range': {
                /**
                 * Date inputs (input)
                 * Time range (select)
                 */
                if (required) {
                    this.element.querySelectorAll('input, select').forEach((el) => {
                        if (el.value.trim() === '') {
                            valid = false;
                        }
                    });
                }
                break;
            }
            case 'radiobuttons': {
                /**
                 * Radio buttons
                 */
                if (
                    required &&
                    this.element.querySelector(
                        'input[type="radio"]:checked',
                    ) === null
                ) {
                    // none are selected
                    valid = false;
                    this.showFeedback(this.requiredStringValue);
                }
                break;
            }
            case 'checkboxes': {
                /**
                 * Checkboxes
                 */
                if (
                    required &&
                    this.element.querySelector(
                        'input[type="checkbox"]:checked',
                    ) === null
                ) {
                    // none are selected
                    valid = false;
                    this.showFeedback(this.requiredStringValue);
                }
                break;
            }
            case 'specified': {
                /**
                 * Specified element
                 */
                if (required) {
                    const input = document.querySelector(requiredEle);
                    const attrType = input.getAttribute('type');

                    if (attrType === 'radio') {
                        const fieldName = input.getAttribute('name');
                        if (
                            document.querySelector(
                                `input[name="${fieldName}"]:checked`,
                            ) === null
                        ) {
                            // No radio buttons selected
                            valid = false;
                            this.showFeedback(this.requiredStringValue);
                        }
                    } else if (input.value.trim() === '') {
                        valid = false;
                        this.showFeedback(this.requiredStringValue);
                    }
                }
                break;
            }
            case 'upload': {
                /**
                 * File upload
                 */
                if (
                    required &&
                    this.element.querySelector('.upload-guid') === null
                ) {
                    // No files uploaded
                    valid = false;
                    this.showFeedback(this.requiredStringValue);
                }
                break;
            }
        }

        if (valid) {
            this.element.classList.remove('is-invalid');
            this.hideFeedback();
        } else {
            this.element.classList.add('is-invalid');
        }

        return valid;
    }

    showFeedback(message) {
        const feedbackId = this.element.getAttribute('id') + '_feedback';
        const feedbackEl = document.createElement('div');
        feedbackEl.dataset.formItemTarget = 'feedback';
        feedbackEl.id = feedbackId;
        feedbackEl.className = 'aiir-c-form-item__feedback';
        feedbackEl.innerText = message;

        const controlEl = this.element.querySelector(
            '.aiir-c-form-item__control',
        );
        if (controlEl !== null) {
            if (this.hasFeedbackTarget) {
                this.feedbackTarget.remove();
            }
            // TODO insert before .aiir-c-form-item__help if present
            controlEl.appendChild(feedbackEl);

            const inputEl = controlEl.querySelector('input');
            if (inputEl !== null) {
                inputEl.setAttribute('aria-errormessage', feedbackId);
            }
        }
    }

    hideFeedback() {
        if (this.hasFeedbackTarget) {
            this.feedbackTarget.remove();

            const inputEl = this.element.querySelector(
                '.aiir-c-form-item__control input',
            );
            if (inputEl !== null) {
                inputEl.removeAttribute('aria-errormessage');
            }
        }
    }
}

// Check this element and its ancestors
function isElementHidden(el) {
    const styles = getComputedStyle(el);
    if (styles.display === 'none' || styles.visibility === 'hidden') {
        return true;
    }
    while (el.parentNode?.style) {
        const pn = el.parentNode;
        const styles = getComputedStyle(pn);
        if (styles.display === 'none' || styles.visibility === 'hidden') {
            return true;
        }
        el = pn;
    }
    return false;
}
