import exposeGlobals from './global';
import Sectional from './ui/Sectional';
import {getViewport} from './utils/env';
import {handleGlobalClasses} from './utils/global_classes';
import './utils/polyfills';

/**
 * Stimulus
 */
import {Application} from '@hotwired/stimulus';
import {definitionsFromContext} from '@hotwired/stimulus-webpack-helpers';
const stimulusApp = Application.start();
const stimulusContext = require.context(
    './stimulus_controllers',
    true,
    /\.js$/,
);
stimulusApp.load(definitionsFromContext(stimulusContext));

window.gm = window.gm || {};
window.AFE = window.AFE || {};

window.gm.env = {
    getViewport, // gm.env.getViewport() is called extensively in themes
};

window.RecaptchaOptions = {
    theme: 'clean',
};

/**
 * Bootstrap
 * DOMContentLoaded is supported by IE9+
 */
document.addEventListener('DOMContentLoaded', function () {
    handleGlobalClasses(); // TODO ideally replace with stimulus controller on <html>
    exposeGlobals();
    Sectional.bind();
});
