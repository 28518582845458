import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['extraDates', 'showMoreButtonContainer'];

    showMore(e) {
        e.preventDefault();
        if (this.hasExtraDatesTarget) {
            this.extraDatesTarget.style.display = 'block';
        }
        if (this.hasShowMoreButtonContainerTarget) {
            this.showMoreButtonContainerTarget.remove();
        }
    }
}
