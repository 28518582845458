import {Controller} from '@hotwired/stimulus';
import {getScript} from '../utils/funcs';

export default class extends Controller {
    static targets = [
        'actions',
        'errors',
        'item',
        'recaptcha',
        'recaptchaResponse',
        'submittingMessage',
    ];

    static values = {
        recaptchaSiteKey: String,
        recaptchaVersion: String,
        pleaseWaitString: {type: String, default: 'Please wait...'},
        checkHighlightedString: {
            type: String,
            default: 'Please check the highlighted parts of this form.',
        },
    };

    id;
    recaptchaWidgetId = null;

    connect() {
        this.element.form = this;
        this.id = this.element.getAttribute('id');
        this.setupCaptcha();
    }

    setupCaptcha() {
        switch (this.recaptchaVersionValue) {
            case 'v3': {
                // Using reCAPTCHA v3
                getScript(
                    `https://www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKeyValue}`,
                );
                break;
            }
            case 'v2-tickbox': {
                // Using reCAPTCHA v2 tickbox
                // We have to give google's api.js an onload callback in the window namespace
                // It will call it when it has loaded and is ready to 'render'
                const callbackName = `recaptcha_onload_callback_form_${this.id}`;

                window[callbackName] = () => {
                    this.recaptchaWidgetId = window.grecaptcha.render(
                        this.recaptchaTarget,
                        {
                            sitekey: this.recaptchaSiteKeyValue,
                        },
                    );
                };

                getScript(
                    `https://www.google.com/recaptcha/api.js?render=explicit&onload=${callbackName}`,
                );
                break;
            }
        }
    }

    /**
     * Call when form is submitted using data-action="submit->form#submit"
     * @param e
     */
    submit(e) {
        /**
         * Check if fields are valid; highlight invalid fields with explanations
         */

        const allFieldsValid = this.validate();

        // ReCAPTCHA v3
        if (allFieldsValid && this.recaptchaVersionValue === 'v3') {
            e.preventDefault();
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(this.recaptchaSiteKeyValue, {action: 'submit'})
                    .then((token) => {
                        this.recaptchaResponseTarget.value = token;

                        if (this.handleValidationResult(true)) {
                            // If true returned, the form wasn't submitted via AJAX
                            //  so we need to re-submit the form normally
                            this.element.submit();
                        }
                    });
            });
            return false;
        }

        if (!this.handleValidationResult(allFieldsValid)) {
            e.preventDefault();
        }
    }

    /**
     * Pass the result of validate()
     * Updates the form actions bar with message/styling
     * Also runs optional custom submit handler - a function named in data-onsubmit of the form
     * - If this returns false, then this function will also return false
     *
     * @param valid
     * @returns {boolean}
     */
    handleValidationResult(valid) {
        if (!valid) {
            /**
             * One or more fields have failed validation, prevent submission
             */
            this.showErrors(`
<p class="aiir-c-error-summary__header">
    ${this.checkHighlightedStringValue}
</p>`);
            return false;
        }

        /**
         * Form fields have passed validation, allow submission
         */
        if (this.hasErrorsTarget) {
            this.errorsTarget.remove();
        }

        /**
         * Hide form actions, show message
         * Don't do this if the form opens a new window
         */
        if (this.element.getAttribute('target') !== '_blank') {
            this.showSubmittingMessage();
        }

        /**
         * Submit the form using Ajax
         */
        if (this.element.dataset?.ajaxsubmit) {
            const formData = new FormData(this.element);
            formData.append('ajaxrequest', '1');
            formData.append('user_agent', navigator.userAgent);

            fetch(this.element.getAttribute('action'), {
                method: this.element.getAttribute('method'),
                body: formData,
            }).then(async (response) => {
                const data = await response.json();

                // To listen for this event and send it to another controller,
                //  add this to the form element:
                //  data-action="form:submit->other-controller#otherMethod"
                this.dispatch('submit', {
                    detail: {params: formData, response: data},
                });

                // Specific handling of user-creatable forms
                if (this.element.dataset?.aiirform) {
                    if (data.success) {
                        if (data?.url) {
                            window.location.href = data.url;
                        } else {
                            this.element.style.display = 'none';
                            const success = document.createElement('div');
                            success.innerHTML = data.content;
                            this.element.after(success);
                        }
                    } else {
                        this.showErrors(`
<p class="aiir-c-error-summary__header">
    ${data.message}
</p>
<ul class="aiir-c-error-summary__list">
    ${data.errors
        .map(
            (error) =>
                `<li class="aiir-c-error-summary__list-item">${
                    error?.id
                        ? `<a href="#formItem_${error.id}">${error.message}</a>`
                        : error.message
                }</li>`,
        )
        .join('')}
</ul>`);
                        this.resetCaptcha();
                        this.restoreFormActions();
                    }
                }
            });

            return false;
        }

        return true;
    }

    /**
     * Validate this form
     * @returns {boolean}
     */
    validate() {
        let allFieldsValid = true;

        this.itemTargets.forEach((item) => {
            if (!item.formItem.validate()) {
                allFieldsValid = false;
            }
        });

        return allFieldsValid;
    }

    // Show 'Please wait...', hide form action buttons
    showSubmittingMessage() {
        const waitMsgEl = document.createElement('div');
        waitMsgEl.dataset.formTarget = 'submittingMessage';
        waitMsgEl.className = 'form-actions';
        waitMsgEl.innerText = this.pleaseWaitStringValue;
        this.actionsTargets.forEach((el) => {
            el.after(waitMsgEl);
            el.style.display = 'none';
        });
    }

    // Remove 'Please wait...', restore form action buttons
    restoreFormActions() {
        if (this.hasSubmittingMessageTarget) {
            this.submittingMessageTargets.forEach((el) => el.remove());
        }
        this.actionsTargets.forEach((el) => (el.style.display = 'block'));
    }

    showErrors(html) {
        if (this.hasErrorsTarget) {
            this.errorsTarget.remove();
        }
        const errorsEl = document.createElement('div');
        errorsEl.dataset.formTarget = 'errors';
        errorsEl.className = 'aiir-c-error-summary';
        errorsEl.innerHTML = html;
        errorsEl.setAttribute('role', 'alert');
        this.element.prepend(errorsEl);
        errorsEl.scrollIntoView();
    }

    resetCaptcha() {
        if (this.recaptchaWidgetId !== null) {
            window.grecaptcha.reset(this.recaptchaWidgetId);
        }
    }
}
