import {Controller} from '@hotwired/stimulus';
import {getScript} from '../utils/funcs';

export default class extends Controller {
    recaptchaWidgetId = null;

    static values = {
        orgLevelId: String,
        recaptchaSiteKey: String,
        recaptchaVersion: String,
    };

    async connect() {
        /**
         * Load initial content
         */
        const res = await fetch(`/_poll_ajax?id=${this.orgLevelIdValue}`);
        this.element.innerHTML = await res.text();

        switch (this.recaptchaVersionValue) {
            case 'v3': {
                getScript(
                    `https://www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKeyValue}`,
                );
                break;
            }
            case 'v2-invisible': {
                const captchaDivEl = document.createElement('div');
                captchaDivEl.className = 'g-recaptcha';
                this.element.after(captchaDivEl);

                // We have to give google's api.js an onload callback in the window namespace
                // It will call it when it has loaded and is ready to 'render'
                const callbackName = `recaptcha_onload_callback_poll_${this.orgLevelIdValue}`;

                window[callbackName] = () => {
                    this.recaptchaWidgetId = window.grecaptcha.render(
                        captchaDivEl,
                        {
                            sitekey: this.recaptchaSiteKeyValue,
                            size: 'invisible',
                            callback: (token) => this._sendVote(token),
                        },
                    );
                };

                getScript(
                    `https://www.google.com/recaptcha/api.js?render=explicit&onload=${callbackName}`,
                );
                break;
            }
        }
    }

    /**
     * On click of option, post to ajax and display result
     */
    vote(e) {
        e.preventDefault();
        this.votedOption = e.params.opt;

        switch (this.recaptchaVersionValue) {
            case 'v3': {
                window.grecaptcha.ready(() => {
                    window.grecaptcha
                        .execute(this.recaptchaSiteKeyValue, {action: 'submit'})
                        .then((token) => {
                            this._sendVote(token);
                        });
                });
                return;
            }
            case 'v2-invisible': {
                // If we're using recaptcha v2, execute the challenge
                // It will then call sendVote through the callback passed to grecaptcha.render()
                if (this.recaptchaWidgetId !== null) {
                    window.grecaptcha.execute(this.recaptchaWidgetId);
                    return;
                }
                break;
            }
        }

        this._sendVote();
    }

    async _sendVote(recaptchaToken = null) {
        const postData = {
            id: this.orgLevelIdValue,
            vote: this.votedOption,
        };

        if (recaptchaToken) {
            postData['g-recaptcha-response'] = recaptchaToken;
        }

        const res = await fetch('/_poll_ajax', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        });
        this.element.innerHTML = await res.text();
    }
}
