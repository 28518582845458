import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'newCategoryContainer',
        'newVenueRadioButton',
        'venueHasSearched',
        'venueSearchResults',
        'venueSearchLoading',
        'venueSearchItem',
    ];

    static classes = ['loadingHidden'];

    toggleNewCategory({currentTarget}) {
        if (this.hasNewCategoryContainerTarget) {
            const val = currentTarget.value;
            this.newCategoryContainerTarget.classList.toggle(
                'is-hidden',
                val !== '0',
            );
        }
    }

    queryDelay;

    venueSearch({currentTarget}) {
        this.venueSearchItemTargets.forEach((t) => t.remove());
        this.venueSearchLoadingTarget.classList.remove(this.loadingHiddenClass);

        const val = currentTarget.value;
        if (val === '') {
            this.venueHasSearchedTarget.classList.add('is-hidden');
            if (this.newVenueRadioButtonTarget.checked) {
                this._toggleNewVenueContainer(false);
            }
            return;
        }

        const ajaxUrl = currentTarget.dataset.ajaxUrl;
        clearTimeout(this.queryDelay);

        this.queryDelay = setTimeout(
            () => this._fetchVenueResults(val, ajaxUrl),
            400,
        );

        this.venueHasSearchedTarget.classList.remove('is-hidden');

        if (this.newVenueRadioButtonTarget.checked) {
            this._toggleNewVenueContainer(true);
        }
    }

    async _fetchVenueResults(q, ajaxUrl) {
        const qs = new URLSearchParams({
            q,
        });
        const res = await fetch(`${ajaxUrl}?${qs}`);
        const html = await res.text();
        this.venueSearchLoadingTarget.insertAdjacentHTML('afterend', html);
        this.venueSearchLoadingTarget.classList.add(this.loadingHiddenClass);
    }

    _toggleNewVenueContainer(show) {
        const controlsId = this.newVenueRadioButtonTarget.getAttribute('aria-controls');
        document.getElementById(controlsId).classList.toggle('is-hidden', !show);
    }
}
