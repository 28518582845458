import {Controller} from '@hotwired/stimulus';

export default class AudioPreviewController extends Controller {
    static playingInstance = null;
    isPlaying = false;
    audioEl = null;

    connect() {
        this.element.audioPreview = this;
    }

    play(e) {
        e.preventDefault();
        if (this.isPlaying) {
            this._stop();
        } else {
            this._play();
        }
    }

    _play() {
        if (AudioPreviewController.playingInstance !== null) {
            AudioPreviewController.playingInstance._stop();
        }

        const audio = new Audio(this.element.getAttribute('href'));
        audio.preload = true;
        audio.addEventListener('ended', () => this._stop());
        audio.play();
        this.audioEl = audio;

        this.element.classList.add('playing');

        this.isPlaying = true;
        AudioPreviewController.playingInstance = this;
    }

    _stop() {
        if (!this.isPlaying) {
            return false;
        }

        this.isPlaying = false;

        if (this.audioEl) {
            this.audioEl.pause();
            //this.audioEl.remove();
            this.audioEl = null;
            AudioPreviewController.playingInstance = null;
            this.element.classList.remove('playing');
        }
    }
}
